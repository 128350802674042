
import { Options, Vue } from 'vue-class-component'
import InvoiceDetailPage from './InvoiceDetailPage.vue'
import axios from '@/plugins/axios'
import { ElMessage } from 'element-plus'
import { toRaw } from 'vue'
interface Result {
  total: number
  list: []
}
interface Res {
  data: []
}
@Options({
  components: {
    InvoiceDetailPage
  },
  props: {
    listInvoices: [],
    ListCards: []
  }
})
export default class InvoiceTablePage extends Vue {
  dialogInvoiceDetail = false
  dataItem = {}
  accountInfo = {}
  dataExport: any
  connection = {
    name: '',
    price: 0,
    quantity: 1
  }

  dataPdf = {
    total: 0,
    releaseDate: '',
    expireDate: '',
    idView: '',
    status: '',
    connections: [{}],
    items: [{}],
    type: '',
  }


  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }

  isSuperAdmin = false
  multipleSelection = []

  async created() {
    this.getAccounInfo()
    this.isSuperAdmin = await this.$store.state.auth.dataLogin.type
  }
  async getAccounInfo() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      data: []
    }
    res = await axios.get('user/account/info', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.accountInfo = res
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  viewDetail(row: any) {
    if (row) {
      for (let item of row.connections) {
        if (item.from) {
          row.connections.splice(item, 1)
        }
      }
    }
    this.dataItem = row
    this.dialogInvoiceDetail = true
  }
  async exportFileCSV() {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result = await axios.get('user/invoice/all', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.dataExport = res.list
    }
    const listData = new Set([
      'idView',
      'packageName',
      'releaseDate',
      'expireDate',
      'total',
      'status'
    ])
    for (let obj of this.dataExport) {
      for (let prop of Object.keys(obj)) {
        if (!listData.has(prop)) {
          delete obj[prop]
        }
        if (typeof obj[prop] === 'object') {
          Object.assign(obj, { total: obj[prop].$numberDecimal })
        }
      }
    }

    await axios
      .post('/user/invoice/export', this.dataExport, { headers: this.headers })
      .then(function (response) {
        window.location = response.data.linkFileExport
        ElMessage.success('Export to csv successfully!')
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error(error)
      })

    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async clickDownload(item: any, type = 'pdf') {
    this.dataPdf.total = item.total
    this.dataPdf.releaseDate = item.releaseDate
    this.dataPdf.expireDate = item.expireDate
    this.dataPdf.idView = item.idView
    this.dataPdf.status = item.status


    if (item.type === 'custom') {
      this.dataPdf.items = item.items
      this.dataPdf.type = item.type
    } else {
      item.connections.forEach((i: any) => {
        this.connection.name = i.connection.name
        this.connection.price = i.price
        this.dataPdf.connections.push(this.connection)
        this.connection = {
          name: '',
          price: 0,
          quantity: 1
        }
      })
    }

    this.dataPdf.connections.splice(0, 1)
    this.dataPdf = Object.assign(this.dataPdf, this.accountInfo)
    await this.$store.dispatch('setLoading', true, { root: true })
    if (type == 'pdf') {
      await axios
        .post('/user/invoice/export-to-pdf', this.dataPdf, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response.data.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
      this.dataPdf.connections = [{}]
      this.dataPdf.items = [{}]
    } else {
      let dataCsv = {
        Total: this.dataPdf.total,
        'Issue Date': this.dataPdf.releaseDate,
        // expireDate: this.dataPdf.expireDate,
        'ID View': this.dataPdf.idView,
        Status: this.dataPdf.status,
        'Package Name': this.dataPdf.connections
      }
      await axios
        .post('/user/invoice/export', dataCsv, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response?.data?.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
      this.dataPdf.connections = [{}]
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleSelectionChange(val: any) {
    let arr: any = []
    for (let v of val) {
      v = toRaw(v)
      arr.push(Object.assign({}, v))
    }
    this.multipleSelection = arr
    this.$emit('seletedInvoices', arr)
  }

  async handleAuthorize(invoice: any) {
    const payload = {
      invoiceId: invoice._id,
      accountId: localStorage.getItem('account')
    }
    await axios
      .post('/user/invoice/authorize', payload, {
        headers: this.headers
      })
      .then((response: any) => {
        if (response.success) {
          ElMessage.success(response.message)
          this.$store.dispatch('reRender')
        } else {
          ElMessage.error(response.message)
        }
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error(error)
      })
  }
}
